import { useEffect, useState } from "react";
import { Nav } from "../Styles/Page-Style";
import axios from "axios";
import { Logout_URL } from "../API/Api";




function Navbar({onLogout}) {

    const [token, setToken] = useState('');
    const [dropdown, setDropdown] = useState(false);


    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("UserData"));
        if(userData && userData.token) {
          setToken(userData.token);
        }
    }, []);

    function handleDropdown() {
        setDropdown(!dropdown);
    }

    function handleLogout() {
        handleSubmit();
    }

    const handleSubmit = () => {
        axios.post(Logout_URL, { token } , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            onLogout();
        })
        .catch(err => console.log(err))
    }


    return(
        <>
          <Nav>
            <div className="logo">
                <img src="image/Logo.png" alt="Logo" />
            </div>
            <div className="nav_profile">
                <div className="profile_btn" onClick={handleDropdown}>
                    <img src="image/profile.png" alt="profile" />
                    <p>Souish Mondal</p>
                </div>
                <div className={`profile_dropdown ${dropdown ? 'active' : ''}`}>
                    <ul>
                        <li onClick={handleLogout}>
                           <i className="fa-solid fa-right-from-bracket"></i>
                           <span>Logout</span>
                        </li>
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="token" defaultValue={token} readOnly />
                        </form>
                    </ul>
                </div>
            </div>
          </Nav>
        </>
    );
}


export default Navbar;
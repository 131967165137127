export const Base_URL = "https://designer-sourish.co.in/Note-App/API";


// Auth URLs 
export const Register_URL = `${Base_URL}/Auth/register.php`;

export const Login_URL = `${Base_URL}/Auth/login.php`;

export const Logout_URL = `${Base_URL}/Auth/logout.php`;


// Notes URLs 
export const NoteShow_URL = `${Base_URL}/Notes/show.php`;

export const NoteCreate_URL = `${Base_URL}/Notes/create.php`;

export const NoteView_URL = `${Base_URL}/Notes/view.php`;

export const NoteDelete_URL = `${Base_URL}/Notes/delete.php`;

export const NoteUpdate_URL = `${Base_URL}/Notes/update.php`;

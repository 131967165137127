import { styled } from "@mui/material";

export const AuthWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 80px 100px;
`;

export const AuthBackGround = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: #40E0D0;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 55%;
        height: 100%;
        background: #009E93;
        transform: skewX(30deg) translateX(-30%);
        box-shadow: 25px 0 50px 5px rgba(0,0,0,0.5);
    }
`;

export const AuthBox = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #FFF;
    border-radius: 10px;
    overflow: hidden;

    .auth_left {
        position: relative;
        width: 50%;
        height: 100%;
        padding: 50px;
        padding-right: 100px;

        .left_inner {
            position: relative;
            width: 600px;
            height: 100%;
            box-shadow: 10px 15px 25px rgba(0,0,0,0.5);
            border-radius: 10px;
            padding: 35px 50px;

            .logo {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                img {
                    position: relative;
                    height: 85px;
                }

                h1 {
                    position: relative;
                    margin-top: 25px;
                    font-family: "Lobster", sans-serif;
                    color: #000;
                    font-size: 35px;
                }
            }

            form {
                position: relative;
                width: 100%;
                height: calc(100% - 154px);
                display: flex;
                flex-direction: column;
                padding-top: 60px;

                .input_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 20px;
                    font-family: "Schibsted Grotesk", sans-serif;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 1px solid #A7A7A7;
                        outline: none;
                        border-radius: 6px;
                        padding: 5px 20px;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                        color: #333;
                        transition: all 0.4s;

                        &:focus,
                        &:valid {
                            border: 1px solid #000;
                            transition: all 0.4s;
                        }
                    }

                    input#password {
                        padding-right: 60px;
                    }

                    label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-left: 20px;
                        font-size: 16px;
                        line-height: 55px;
                        color: #000;
                        font-weight: 500;
                        pointer-events: none;
                        transition: all 0.7s;
                    }

                    input:focus ~ label,
                    input:valid ~ label {
                        top: -7px;
                        left: 10px;
                        color: #009E93;
                        padding: 0px 8px;
                        font-size: 14px;
                        line-height: 1;
                        background: #FFF;
                        transition: all 0.7s;
                    }

                    a {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 60px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 17px;
                        color: #333;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }

                .remember_forgot {
                    position: relative;
                    margin-top: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-family: "Schibsted Grotesk", sans-serif;

                    .remember_box {
                        position: relative;
                        display: flex;
                        align-items: center;

                        input[type="checkbox"] {
                            display: none;
                        }

                        label {
                            position: relative;
                            display: flex;
                            align-items: center;
                            color: #333;

                            .check_box {
                                position: relative;
                                width: 17px;
                                height: 17px;
                                border: 1px solid #A7A7A7;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 12px;
                                color: #FFF;
                                margin-right: 6px;
                            }
                        }

                        input[type="checkbox"]:checked ~ label .check_box {
                            border: 1px solid #009E93;
                            background: #009E93;
                        }
                    }

                    .forgot_box {
                        position: relative;

                        a {
                            position: relative;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 500;
                            color: #009E93;
                        }
                    }
                }

                .btn_box {
                    position: relative;
                    margin-top: auto;

                    button {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        font-family: "Schibsted Grotesk", sans-serif;
                        font-size: 18px;
                        background: #009E93;
                        border: none;
                        outline: none;
                        border-radius: 6px;
                        text-transform: uppercase;
                        color: #FFF;
                        font-weight: 500;
                        letter-spacing: 0.66px;
                        cursor: pointer;
                    }
                }

                .redirect_box {
                    position: relative;
                    width: 100%;
                    margin-top: 15px;
                    text-align: center;
                    font-family: "Schibsted Grotesk", sans-serif;

                    p {
                        font-size: 15px;
                        color: #555;
                    }

                    a {
                        font-weight: 600;
                        color: #009E93;
                        text-decoration: none;
                    }
                }
            }
        }

        .left_inner.up_appear {
            transform: translateY(-150%);
        }

        .left_inner.left_appear {
            transform: translateX(-150%);
        }
    }

    .auth_right {
        position: relative;
        width: 50%;
        height: 100%;
        padding-left: 60px;
        display: flex;

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .auth_right.right_appear {
        transform: translateX(150%);
    }

    .auth_right.fade_appear {
        opacity: 0;
        transform: scale(0);
    }

    .up_appear {
        animation: upAppear 2s linear forwards;

        @keyframes upAppear {
            0% {
                transform: translateY(-150%);
            }
            70% {
                transform: translateY(0%);
            }
            80% {
                transform: translateY(-10%);
            }
            100% {
                transform: translateY(0%);
            }
        }
    }

    .right_appear {
        animation: rightAppear 1s linear forwards;
        animation-delay: 0.3s;

        @keyframes rightAppear {
            0% {
                transform: translateX(150%);
            }
            100% {
                transform: translateY(0%);
            }
        }
    }

    .left_appear {
        animation: leftAppear 1.5s linear forwards;

        @keyframes leftAppear {
            0% {
                transform: translateX(-150%);
            }
            80% {
                transform: translateX(40%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }

    .fade_appear {
        animation: fadeAppear 1.5s linear forwards;
        animation-delay: 1s;

        @keyframes fadeAppear {
            0% {
                opacity: 0;
                transform: scale(0);
            }
            80% {
                opacity: 1;
                transform: scale(1.2);
            }
            100% {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
`;
import { useEffect } from "react";
import { ModalViewWrapper, ViewBox } from "../Styles/Modal-Style";



function ModalView({viewNoteData, activeStatus, handleViewModal, setUpdateNote, setUpdateNoteId, setFormModal}) {

    useEffect(() => {
        console.log(viewNoteData);
    }, [viewNoteData]);

    function handleEdit(id) {
        setUpdateNote(true);
        setUpdateNoteId(id);
        handleViewModal();
        setFormModal(true);
    }

    return(
        <>
            <ModalViewWrapper className={activeStatus ? 'active' : ''}>
                <ViewBox className={activeStatus ? 'active' : ''}>
                    {
                        viewNoteData &&
                        <div className="view_inner">
                            <div className="header">
                                <h1>Your <span>{viewNoteData.type}</span> Note <img src={`image/${viewNoteData.type}.svg`} alt="" /></h1>
                                {
                                    !viewNoteData.uday && <p><i class="fa-solid fa-circle"></i>Created on: <span>{viewNoteData.time}, {viewNoteData.day}, {viewNoteData.date}</span></p>
                                }
                            </div>
                            {
                                viewNoteData.uday && <div className="update_time_status"><p><i class="fa-solid fa-circle"></i>Created on: <span>{viewNoteData.time}, {viewNoteData.day}, {viewNoteData.date}</span></p> <p><i class="fa-solid fa-circle"></i>Last Updated on: <span>{viewNoteData.utime}, {viewNoteData.uday}, {viewNoteData.udate}</span></p></div>
                            }
                            <div className="note_title">
                                <h3>{viewNoteData.title}</h3>
                            </div>
                            <div className="note_summary">
                                <p>{viewNoteData.summary}</p>
                            </div>
                            <div className="note_btns">
                                <a onClick={(e) => handleEdit(viewNoteData.id)}><span><i class="fa-solid fa-pen-to-square"></i>Edit</span></a>
                                <a onClick={handleViewModal}><span><i class="fa-solid fa-xmark"></i>Close</span></a>
                            </div>
                        </div>
                    }
                </ViewBox>
            </ModalViewWrapper>
        </>
    );
}



export default ModalView;
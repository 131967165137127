import { useEffect } from "react";
import axios from "axios";
import { NoteDelete_URL, NoteView_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';




function Note({note, noteData, updateNoteInput, setViewNote, handleViewModal, setUpdateNoteId, setUpdateNote, handleFormModal}) {

    function showMenu(e) {
        let elem = e.currentTarget.parentElement;
        elem.classList.toggle("show");
        e.stopPropagation();
      }
    
      useEffect(() => {
        // Add event listener to handle clicks outside the menu
        document.addEventListener("click", handleOutsideClick);
      
        // Cleanup function to remove event listener when component unmounts
        return () => {
          document.removeEventListener("click", handleOutsideClick);
        };
      }, []);
    
      function handleOutsideClick(event) {
        // Check if the clicked element is not a descendant of any menu
        const allMenus = document.querySelectorAll(".note_options");
        allMenus.forEach(menu => {
          if (!menu.contains(event.target)) {
            menu.parentElement.classList.remove("show");
          }
        });
      }

      function viewNote(event, id) {

        let noteIndex = {
          index: id
        }

        axios.post(NoteView_URL, noteIndex, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        .then(res => {
          // console.log(res.data.notes[0]);
          setViewNote(res.data.notes[0]);
          handleViewModal();
        })
        .catch(err => {
          console.log(err)
        })

        let drop = event.currentTarget.parentElement.parentElement;
        drop.classList.remove("show");

      }

      function deleteNote(event, id) {

        let notes = [...noteData];
        notes.splice(id, 1);
        
        let input = {
          index: id
        }

        axios.post(NoteDelete_URL, input, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        .then(res => {
          console.log(res);
          toast.warn('You Have Deleted a Note.', {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          updateNoteInput(notes);
        })
        .catch(err => {
          console.log(err)
        })

        let drop = event.currentTarget.parentElement.parentElement;
        drop.classList.remove("show");
      }

      function updateNote(event, id) {
        setUpdateNoteId(id);
        setUpdateNote(true);
        toast.info('You Are Updating a Saved Note.', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        handleFormModal();
        let drop = event.currentTarget.parentElement.parentElement;
        drop.classList.remove("show");
      }
      

    return(
        <>
            <div className="note_box" key={note.id}>
                <div className="box_icon">
                  <div className="icon_inner">
                      <div className="icon_box">
                        <img src={`image/${note.type}.svg`} alt="Icon" />
                      </div>
                  </div>
                </div>
                <div className="box_inner">
                  <div className="note_inner">
                      <div className="note_head">
                        <h3>{note.title}</h3>
                      </div>
                      <div className="note_summary">
                        <p>{note.summary}</p>
                      </div>
                      <div className="note_bottom">
                        <div className="left_side">
                            <p>Posted on:</p>
                            <li>
                              <span>{note.time}.</span>
                              <span>{note.date}</span>
                            </li>
                        </div>
                        <div className="right_side">
                            <span onClick={showMenu}><i className="fa-solid fa-ellipsis"></i></span>
                            <div className="note_options">
                              <li onClick={(e) => viewNote(e, note.id)}><i className="fa-solid fa-eye"></i>View</li>
                              <li onClick={(e) => updateNote(e, note.id)}><i className="fa-solid fa-pen"></i>Edit</li>
                              <li onClick={(e) => deleteNote(e, note.id)}><i className="fa-solid fa-trash"></i>Delete</li>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </>
    );
}



export default Note;
import { styled } from "@mui/material";


export const ModalWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    pointer-events: none;
    backdrop-filter: blur(5px);
    opacity: 0;
    transform: scale(0);
    transform-origin: right bottom;
    transition: all 0.7s;
    transition-delay: transform 0.2s;

    &.active {
        pointer-events: initial;
        transform: scale(1);
        opacity: 1;
        transform-origin: right bottom;
        transition-delay: opacity 0.2s;
        transition: all 0.7s;
    }
`;

export const ModalBox = styled('div')`
    position: relative;
    width: 600px;
    min-height: 300px;
    background: #FFF;
    border-radius: 15px;
    box-shadow: 12px 18px 30px rgba(64, 224, 208, 0.658);
    padding: 35px 45px;
    padding-bottom: 45px;

    .modal_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .heading {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                position: relative;
                height: 12px;

                &:first-of-type {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 60px;
                        height: 3px;
                        background: #009E93;
                        border-radius: 20px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 80px;
                        height: 3px;
                        background: #009E93;
                        border-radius: 20px;
                    }
                }

                &:last-of-type {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 60px;
                        height: 3px;
                        background: #009E93;
                        border-radius: 20px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 80px;
                        height: 3px;
                        background: #009E93;
                        border-radius: 20px;
                    }
                }

            }

            h2 {
                position: relative;
                margin: 0px 20px;
                font-size: 26px;
                font-family: "Lobster", sans-serif;
            }
        }

        .form_sec {
            position: relative;
            margin-top: 40px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .form_box {
                position: relative;
                width: 100%;
                margin-bottom: 25px;

                input {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    border: 1px solid #A7A7A7;
                    outline: none;
                    border-radius: 6px;
                    padding: 5px 20px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.4px;
                    color: #333;
                    transition: all 0.4s;

                    &:focus,
                    &:valid {
                        border: 1px solid #000;
                        transition: all 0.4s;
                    }
                }

                textarea {
                    position: relative;
                    width: 100%;
                    height: 120px;
                    border: 1px solid #A7A7A7;
                    outline: none;
                    resize: none;
                    border-radius: 6px;
                    padding: 15px 20px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.4px;
                    color: #333;
                    transition: all 0.4s;

                    &:focus,
                    &:valid {
                        border: 1px solid #000;
                        transition: all 0.4s;
                    }
                }

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-left: 20px;
                    font-size: 16px;
                    line-height: 55px;
                    color: #555;
                    font-weight: 500;
                    pointer-events: none;
                    transition: all 0.7s;
                }

                input:focus ~ label,
                input:valid ~ label,
                textarea:focus ~ label,
                textarea:valid ~ label {
                    top: -7px;
                    left: 10px;
                    color: #009E93;
                    padding: 0px 8px;
                    font-size: 14px;
                    line-height: 1;
                    background: #FFF;
                    transition: all 0.7s;
                }

                .dropdown_btn {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    border: 1px solid #A7A7A7;
                    border-radius: 6px;
                    padding: 5px 20px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    p {
                        position: relative;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                        color: #333;
                    }

                    i {
                        position: relative;
                        font-size: 16px;
                        margin-left: auto;
                    }
                }

                .dropdown_options {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    max-height: 0px;
                    background: #FFF;
                    box-shadow: 6px 8px 20px rgba(64, 224, 208, 0.658);
                    z-index: 50;
                    border-radius: 6px;
                    overflow: hidden;
                    transition: all 0.7s;

                    &.active {
                        max-height: 250px;
                        transition: all 0.7s;
                    }

                    ul {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 10px 25px ;

                        span {
                        position: relative;
                        padding: 10px 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #555;
                        transition: all 0.3s;
                        cursor: pointer;

                        &:hover {
                            color: #009E93;
                            transition: all 0.3s;
                        }
                    }
                    }
                }
            }

            .date_time_zone {
                position: relative;
                width: 50%;
                display: flex;
                flex-direction: column;

                .form_box {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .form_btn {
                position: relative;
                width: 50%;
                display: flex;
                justify-content: flex-end;

                button {
                    position: relative;
                    border: none;
                    outline: none;
                    background: #009E93;
                    width: 180px;
                    height: 55px;
                    color: #FFF;
                    font-size: 16px;
                    letter-spacing: 0.66px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
`;

export const ModalViewWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    backdrop-filter: blur(7px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }
`;

export const ViewBox = styled('div')`
    position: relative;
    width: 950px;
    min-height: 250px;
    background: #FFF;
    border-radius: 15px;
    box-shadow: 12px 18px 30px rgba(64, 224, 208, 0.658);
    padding: 40px 45px;
    padding-bottom: 45px;
    transform: translateX(-100px);
    transition: all 0.7s;

    &.active {
        transform: translateX(0px);
        transition: all 0.7s;
    }

    .view_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .header {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            h1 {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 35px;
                line-height: 1;
                font-family: "Poetsen One", sans-serif;
                color: #40E0D0;

                span {
                    position: relative;
                    margin: 0px 6px;
                    font-family: "Poetsen One", sans-serif;
                    color: #009E93;
                }

                img {
                    position: relative;
                    margin-left: 15px;
                    width: 35px;
                }
            }

            p {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 15px;
                color: #111;
                font-weight: 500;

                i {
                    position: relative;
                    margin-right: 5px;
                    font-size: 5px;
                    color: #111;
                }

                span {
                    position: relative;
                    margin-left: 6px;
                    color: #777;
                }
            }
        }

        .update_time_status {
            position: relative;
            width: 100%;
            padding-top: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 15px;
                color: #111;
                font-weight: 500;

                i {
                    position: relative;
                    margin-right: 5px;
                    font-size: 5px;
                    color: #111;
                }

                span {
                    position: relative;
                    margin-left: 6px;
                    color: #777;
                }
            }
        }

        .note_title {
            position: relative;
            margin-top: 40px;
            width: 100%;

            h3 {
                position: relative;
                font-size: 27px;
            }
        }

        .note_summary {
            position: relative;
            margin-top: 20px;
            width: 100%;
            border: 1px solid #cbcbcb;
            padding: 20px 25px;
            padding-right: 0;
            border-radius: 8px;

            p {
                position: relative;
                width: 100%;
                min-height: 150px;
                max-height: 250px;
                padding-right: 25px;
                overflow-y: auto;
                font-size: 16px;
                line-height: 1.5;
                color: #222;
            }
        }

        .note_btns {
            position: relative;
            margin-top: 35px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;


            a {
                position: relative;
                width: 180px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                overflow: hidden;
                border-radius: 5px;

                span {
                    z-index: 5;
                }

                i {
                    position: relative;
                    font-size: 17px;
                    margin-right: 6px;
                }

                &:first-of-type {
                    border: 2px solid #009E93;
                    color: #FFF;
                    transition: all 0.5s;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        background: #009E93;
                        transform-origin: left;
                        transition: transform 0.5s;
                    }
                    &:hover::before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }
                    &:hover {
                        color: #009E93;
                        transition: all 0.5s;
                    }
                }

                &:last-of-type {
                    margin-left: 20px;
                    border: 2px solid #000;
                    color: #FFF;
                    transition: all 0.5s;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        background: #000;
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover::before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }
                    &:hover {
                        color: #000;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
`;
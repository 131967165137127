import { NoteShow_URL } from "../API/Api";
import ModalForm from "../Components/ModalForm";
import ModalView from "../Components/ModalView";
import axios from "axios";
import { NoteWrapper } from "../Styles/Page-Style";
import { useEffect, useState } from "react";
import Note from "../Components/Note";




function NoteIndex() {

  const [notes, setNotes] = useState([]);
  const [count, setCount] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [noteInput, setNoteInput] = useState([]);
  const [viewNote, setViewNote] = useState();

  const [updateNote, setUpdateNote] = useState(false);
  const [updateNoteId, setUpdateNoteId] = useState();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("UserData")).token;

    console.log(token);

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios.get(NoteShow_URL, config)
    .then(res => {
      console.log(res.data);
      setNotes(res.data.notes);
      setCount(true);
    })
    .catch(err => {
      // console.log(err);
      setCount(false);
    });

  }, [noteInput]);

  function updateNoteInput(newNoteInput) {
    setNoteInput(newNoteInput);
  }

  function handleFormModal() {
    if(updateNote) {
      setUpdateNote(false);
    }
    setFormModal(!formModal);
  }

  function handleViewModal() {
    setViewModal(!viewModal);
  }

  const printReceipt = () => {
      const receiptContent = `
          <html>
          <head>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      margin: 0;
                      padding: 10px;
                      height: 150mm;
                  }
                  .receipt {
                      width: 100%;
                      max-width: 90mm;
                      margin: 0 auto;
                      border: 1px solid #ccc;
                      padding: 10px;
                      box-sizing: border-box;
                  }
                  .receipt-header {
                      text-align: center;
                      margin-bottom: 10px;
                  }
                  .receipt-header h2 {
                      margin: 0;
                  }
                  .receipt-details {
                      margin-bottom: 10px;
                  }
                  .receipt-details p {
                      margin: 5px 0;
                  }
                  .receipt-table {
                      width: 100%;
                      border-collapse: collapse;
                  }
                  .receipt-table th, .receipt-table td {
                      border: 1px solid #ccc;
                      padding: 5px;
                      text-align: left;
                  }
                  .receipt-table th {
                      background-color: #f2f2f2;
                  }
                  .receipt-total {
                      margin-top: 10px;
                      text-align: right;
                      font-weight: bold;
                  }
                  .receipt-footer {
                      margin-top: 10px;
                      text-align: center;
                  }
              </style>
          </head>
          <body>
              <div class="receipt">
                  <div class="receipt-header">
                      <h2>Booking Receipt</h2>
                  </div>
                  <div class="receipt-details">
                      <p><strong>Theater:</strong> Sreelekha Theater</p>
                      <p><strong>Movie:</strong> The Avengers</p>
                      <p><strong>Date:</strong> 25 Jun, 2024</p>
                      <p><strong>Time:</strong> 10:30 AM</p>
                  </div>
                  <table class="receipt-table">
                      <thead>
                          <tr>
                              <th>Section</th>
                              <th>Seats</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td>2nd Class</td>
                            <td>25, 26</td>
                        </tr>
                      </tbody>
                  </table>
                  <div class="receipt-total">
                      <p><strong>Total Price:</strong> 200</p>
                  </div>
                  <div class="receipt-footer">
                      <p>Thank you for booking!</p>
                  </div>
              </div>
          </body>
          </html>
      `;

      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);

      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(receiptContent);
      doc.close();

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      setTimeout(() => {
          document.body.removeChild(iframe);
      }, 1000);
  };

  const handleBooking = (e) => {
    printReceipt();
  };


    return(
        <>
          <NoteWrapper>
            <div className="page_inner">
              {
                count ? 
                (
                  notes && notes.map((note, index) => <Note note={note} key={index} noteData={notes} updateNoteInput={updateNoteInput} handleViewModal={handleViewModal} setViewNote={setViewNote} setUpdateNoteId={setUpdateNoteId} setUpdateNote={setUpdateNote} handleFormModal={handleFormModal} />)
                )
                :
                <div className="empty_notes">
                  <h1>You Don't Have Any Saved Notes.</h1>
                </div>
              }
            </div>
            <div className="note_btn">
              <a onClick={handleBooking}>Print</a>
              <span className={formModal ? 'active' : ''} onClick={handleFormModal}><i className="fa-solid fa-plus"></i></span>
            </div>
          </NoteWrapper>

          <ModalForm activeStatus={formModal} handleFormModal={handleFormModal} updateNoteInput={updateNoteInput} updateNote={updateNote} updateNoteId={updateNoteId} />

          <ModalView activeStatus={viewModal} handleViewModal={handleViewModal} viewNoteData={viewNote} setUpdateNote={setUpdateNote} setUpdateNoteId={setUpdateNoteId} setFormModal={setFormModal} />
        </>
    );
}


export default NoteIndex;
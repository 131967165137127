import { useEffect, useRef, useState } from "react";
import { ModalBox, ModalWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { NoteCreate_URL, NoteUpdate_URL, NoteView_URL } from "../API/Api";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';




function ModalForm({activeStatus, updateNoteInput, handleFormModal, updateNoteId, updateNote}) {

  const [currentTime, setCurrentTime] = useState(new Date());
  const [token, setToken] = useState('');
  const [selectedType, setSelectedType] = useState("Choose Note Type");
  const [dropdown, setDropdown] = useState(false);
  const [date, setDate] = useState('');
  const [day, setDay] = useState('');

  const timeOptions = { hour: 'numeric', minute: 'numeric' };
  const types = ['Sad', 'Happy', 'Memorizable'];


  const [data, setData] = useState({title: "", summary: ""});

  const tokenRef = useRef();
  const typeRef = useRef();
  const titleRef = useRef();
  const summaryRef = useRef();
  const timeRef = useRef();
  const dayRef = useRef();
  const dateRef = useRef();


  function handleInput(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }


  useEffect(() => {

    let userData = JSON.parse(localStorage.getItem("UserData"));
    if(userData && userData.token) {
      setToken(userData.token);
    }

    var currentDate = new Date();
    let currDay = currentDate.getDate(),
        currMonth = currentDate.getMonth(),
        currYear = currentDate.getFullYear(),
        cDay = currentDate.getDay();
    
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]; 
    
    let currDate = currDay + ' ' + months[currMonth] + ', ' + currYear;
    let today = daysOfWeek[cDay];

    setDate(currDate);
    setDay(today);

    const updateCurrentTime = () => {
      setCurrentTime(new Date());
    };

    updateCurrentTime();

    const intervalId = setInterval(updateCurrentTime, 1000);
    return () => clearInterval(intervalId);

  }, []);

  useEffect(() => {

    if(updateNote){
      
      let updateId = {
        index: updateNoteId
      }

      axios.post(NoteView_URL, updateId, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        // console.log(res.data.notes[0]);
        let resData = res.data.notes[0];
        const prevData = {
          title: resData.title,
          summary: resData.summary,
        }
        setData(prevData);
        document.getElementById('selectedType').innerText = resData.type;
      })
      .catch(err => {
        console.log(err)
      })
    } else{
      document.getElementById('selectedType').innerText = "Choose Note Type";
      setData({title: "", summary: ""});
    }

  }, [updateNote]);


  function typeDropdownHandle() {
    setDropdown(!dropdown);
  }

  function handleSubmit(e) {

    e.preventDefault(); // Prevent form 

    if(updateNote){
      
      const updateData = {
        id: updateNoteId,
        type: typeRef.current.value,
        day: day,
        time: currentTime.toLocaleTimeString([], timeOptions),
        date: date,
        title: titleRef.current.value,
        summary: summaryRef.current.value
      };

      setData({ title: titleRef.current.value, summary: summaryRef.current.value });

      axios.post(NoteUpdate_URL, updateData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        console.log(res);
        document.getElementById('selectedType').innerText = "Choose Note Type";
        setData({ title: "", summary: ""});
        toast.success('You Have Updated This Note !!', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        updateNoteInput(updateData);
        handleFormModal();
      })
      .catch(err => {
        console.log(err);
      })

    } else{

      const inputData = {
        token: tokenRef.current.value,
        type: typeRef.current.value,
        day: day,
        time: currentTime.toLocaleTimeString([], timeOptions),
        date: date,
        title: titleRef.current.value,
        summary: summaryRef.current.value
      };
  
      setData({ title: titleRef.current.value, summary: summaryRef.current.value });
  
  
      axios.post(NoteCreate_URL, inputData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        console.log(res);
        document.getElementById('selectedType').innerText = "Choose Note Type";
        setData({ title: "", summary: ""});
        toast.success('You Have Saved a New Note !!', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        updateNoteInput(inputData);
        handleFormModal();
      })
      .catch(err => {
        console.log(err)
      })
    }

  }

    return(
        <>
          <ModalWrapper className={activeStatus ? 'active' : ''}>
            <ModalBox className={activeStatus ? 'active' : ''}>
              <div className="modal_inner">
                <div className="heading">
                  <span></span>
                  {updateNote ? <h2>Update Note</h2> : <h2>Add New Note</h2>}
                  <span></span>
                </div>
                <form className="form_sec" onSubmit={handleSubmit}>
                  <input type="hidden" name="token" className="autoFill" ref={tokenRef} value={token} readOnly />
                  <input type="hidden" name="day" className="autoFill" ref={dayRef} value={day}  readOnly />
                  <div className="form_box">
                    <div className='dropdown_btn' onClick={typeDropdownHandle}>
                        <p id='selectedType'>{selectedType}</p>
                        <input type="hidden" name='type' id='nType' ref={typeRef} />
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                    <div className={`dropdown_options ${dropdown ? 'active' : ''}`}>
                      <ul>
                        {
                          types.map(type => (
                            <span 
                              onClick={(e) => {
                                setSelectedType(type)
                                setDropdown(false)
                                document.getElementById('nType').value = type
                                document.getElementById('nType').click()
                              }}>{type}</span>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="form_box">
                    <input type="text" name="title" value={data.title || ""} ref={titleRef} onChange={handleInput} required />
                    <label>Note Title</label>
                  </div>
                  <div className="form_box">
                    <textarea name="summary" value={data.summary || ""} ref={summaryRef} onChange={handleInput} required></textarea>
                    <label>Note Summary</label>
                  </div>
                  <div className="date_time_zone">
                    <div className="form_box">
                      <input type="text" name="time" className="autoFill" value={currentTime.toLocaleTimeString([], timeOptions)} ref={timeRef} required />
                      <label>Current Time</label>
                    </div>
                    <div className="form_box">
                      <input type="text" name="date" className="autoFill" value={date} ref={dateRef} required />
                      <label>Current Date</label>
                    </div>
                  </div>
                  <div className="form_btn">
                    {updateNote ? <button>Update</button> : <button>Save</button>}
                  </div>
                </form>
              </div>
            </ModalBox>
          </ModalWrapper>
        </>
    );
}


export default ModalForm;

import { styled } from "@mui/material";


export const PageWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    background: #dbfffb;
    display: flex;
    flex-direction: column;
`;


export const Nav = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    padding: 5px 90px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 101;

    .logo {
        position: relative;
        display: flex;
        height: 100%;

        img {
            position: relative;
            height: 100%;
            width: auto;
        }
    }

    .nav_profile {
        position: relative;
        height: 100%;

        .profile_btn {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                position: relative;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }

            p {
                position: relative;
                margin-left: 10px;
                font-size: 17px;
                font-weight: 500;
                color: #222;
                max-width: 130px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .profile_dropdown {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            max-height: 0;
            overflow: hidden;
            background: #FFF;
            border-radius: 5px;
            box-shadow: 5px 7px 10px rgba(0,0,0,0.3);
            transition: all 0.4s;

            &.active {
                max-height: 100px;
                transition: all 0.4s;
            }

            ul {
                position: relative;
                width: 100%;
                padding: 15px 25px;

                li {
                    position: relative;
                    width: 100%;
                    list-style: none;
                    display: flex;
                    align-items: center; 
                    color: #555;
                    cursor: pointer;

                    &:hover {
                        color: #009E93;
                    }

                    i {
                        font-size: 17px;
                    }

                    span {
                        position: relative;
                        margin-left: 10px;
                        font-size: 17px;
                        font-weight: 500;
                    }
                }

                form {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 1150px) {
        padding: 5px 70px;
    }
`;

export const NoteWrapper = styled('div')`
    position: relative;
    margin-top: auto;
    width: 100%;
    height: calc(100% - 75px);
    padding: 60px 70px;
    padding-right: 0;

    .page_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding-right: 70px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;


        .note_box {
            position: relative;
            width: 20%;
            padding: 25px;
            height: max-content;
            margin-top: 20px;
            margin-bottom: 20px;

            .box_icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 120px;
                z-index: 5;
                display: flex;
                justify-content: center;

                .icon_inner {
                    position: relative;
                    width: 140px;
                    height: 110px;
                    padding: 0px 20px 10px 20px;
                    background: #dbfffb;
                    border-bottom-right-radius: 50%;
                    border-bottom-left-radius: 50%;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 25px;
                        left: -43px;
                        width: 45px;
                        height: 45px;
                        background: transparent;
                        border-top-right-radius: 50px;
                        box-shadow: 15px -15px 0 15px #dbfffb;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 25px;
                        right: -43px;
                        width: 45px;
                        height: 45px;
                        background: transparent;
                        border-top-left-radius: 50px;
                        box-shadow: -15px -15px 0 15px #dbfffb;
                    }

                    .icon_box {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        background: #dbfffb;
                        border-radius: 50%;
                        display: flex;
                        margin-top: -10px;
                        z-index: 5;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            filter: drop-shadow(6px 7px 10px #40e0d094);
                        }
                    }
                }
            }

            .box_inner {
                position: relative;
                width: 100%;
                min-height: 295px;
                padding: 25px 35px;
                padding-top: 110px;
                border-radius: 8px;
                background: linear-gradient(135deg, #c0fff9, #77f3e7);
                box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.114);

                .note_inner {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .note_head {
                        position: relative;
                        width: 100%;

                        h3 {
                            position: relative;
                            width: 100%;
                            font-size: 23px;
                            font-weight: 500;
                            color: #000;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-family: "Poetsen One", sans-serif;
                        }
                    }

                    .note_summary {
                        position: relative;
                        width: 100%;
                        margin-top: 20px;

                        p {
                            position: relative;
                            width: 100%;
                            height: 90px;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 16px;
                            line-height: 1.4;
                            color: #555;
                        }
                    }

                    .note_bottom {
                        position: relative;
                        margin-top: 25px;
                        width: 100%;
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;

                        .left_side {
                            position: relative;
                            display: flex;
                            flex-direction: column;

                            p {
                                position: relative;
                                font-size: 14px;
                                font-weight: 500;
                                color: #222;
                            }

                            li {
                                position: relative;
                                margin-top: 3px;
                                list-style: none;
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                color: #666;

                                span {
                                    position: relative;

                                    &:first-of-type{
                                        margin-right: 6px;
                                    }
                                }
                            }
                        }

                        .right_side {
                            position: relative;

                            span {
                                position: relative;
                                font-size: 17px;
                                color: #444;
                                cursor: pointer;
                            }

                            .note_options {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                background: #FFF;
                                display: flex;
                                flex-direction: column;
                                padding: 13px 14px 13px 13px;
                                border-radius: 5px;
                                transform: scale(0);
                                transform-origin: right bottom;
                                transition: all 0.4s;

                                li {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    font-size: 13px;
                                    color: #444;
                                    margin-bottom: 8px;
                                    cursor: pointer;
                                    transition: all 0.3s;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    &:hover {
                                        color: #009E93;
                                        transition: all 0.3s;
                                    }

                                    i {
                                        position: relative;
                                        width: 21px;
                                    }
                                }
                            }

                            &.show {
                                .note_options {
                                    transform: scale(1);
                                    transform-origin: right bottom;
                                    transition: all 0.4s;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1800px) {
                width: 25%;
            }

            @media (max-width: 1440px) {
                width: 33.33%;
            }

            @media (max-width: 1024px) {
                width: 50%;
            }
        }


        .empty_notes {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            h1 {
                position: relative;
                font-size: 45px;
                color: #555;
                font-family: "Lobster", sans-serif;
            }
        }
    }

    .note_btn {
        position: fixed;
        right: 50px;
        bottom: 50px;
        z-index: 9999;
        display: flex;
        align-items: center;

        a {
            position: relative;
            margin-right: 25px;
            width: 200px;
            height: 65px;
            border-radius: 30px;
            background: linear-gradient(135deg, #5bf2e3, #009E93);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            font-weight: 500;
            color: #FFF;
            cursor: pointer;
            box-shadow: 7px 10px 20px rgba(0,0,0,0.3);

            i {
                position: relative;
                transition: all 0.6s;
            }

            &.active {
                i {
                    transform: rotate(135deg);
                    transition: all 0.6s;
                }
            }
        }

        span {
            position: relative;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: linear-gradient(135deg, #5bf2e3, #009E93);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 27px;
            color: #FFF;
            cursor: pointer;
            box-shadow: 7px 10px 20px rgba(0,0,0,0.3);

            i {
                position: relative;
                transition: all 0.6s;
            }

            &.active {
                i {
                    transform: rotate(135deg);
                    transition: all 0.6s;
                }
            }
        }
    }

    @media (max-width: 1150px) {
        padding: 60px 50px;
    }
`;
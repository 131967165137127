import { Outlet } from "react-router-dom";
import { AuthBackGround, AuthWrapper } from "../Styles/Auth-Style";



function AuthLayout() {
    return (
        <AuthWrapper>
            <AuthBackGround />
            <Outlet />
        </AuthWrapper>
    );
}

export default AuthLayout;
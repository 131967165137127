import { Link, useNavigate } from "react-router-dom";
import { AuthBox } from "../Styles/Auth-Style";
import { useEffect, useState } from "react";
import axios from "axios";
import { Login_URL } from "../API/Api";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function LoginPage() {


    useEffect(() => {
        const isLoggedIn = localStorage.getItem("Login");
        if (isLoggedIn && isLoggedIn !== "true") {
            toast.warn('You Have Logged Out !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }
    }, []);

    const [show, setShow] = useState(false);
    const [cred, setCred] = useState({
        username: '',
        password: '',
    })

    const navigate = useNavigate();

    function handlePassowrd() {
        setShow(!show);
    }

    const handleInput = (e) => {
        setCred({...cred, [e.target.name]: e.target.value});
    }


    function handleSubmit(e) {
        e.preventDefault();
        axios.post(Login_URL, cred, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data);
            localStorage.setItem("Login", true);
            let userData = {
                userId: res.data.userId,
                token: res.data.token,
            }
            localStorage.setItem("UserData", JSON.stringify(userData));
            navigate ('/notes');
        })
        .catch(err => {
            console.log(err);

            toast.error('Invalid Credentials', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        });
    }

    return (
        <>
          <ToastContainer />
          <AuthBox>
            <div className="auth_left">
                <div className="left_inner up_appear">
                    <div className="logo">
                        <img src="image/Logo.png" alt="Logo" />
                        <h1>!! Welcome !!</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="input_box">
                            <input type="text" name="username" onChange={handleInput} required />
                            <label>Username</label>
                        </div>
                        <div className="input_box">
                            <input type={show ? 'text' : 'password'} id="password" name="password" onChange={handleInput} required />
                            <label>Password</label>
                            <a onClick={handlePassowrd}><i className={`fa-solid ${show ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                        </div>
                        <div className="remember_forgot">
                            <div className="remember_box">
                                <input type="checkbox" name="check" id="remember_check" />
                                <label htmlFor="remember_check">
                                    <div className="check_box"><i className="fa-solid fa-check"></i></div>
                                    <p>Remember Me</p>
                                </label>
                            </div>
                            <div className="forgot_box">
                                <Link>Forgot Password</Link>
                            </div>
                        </div>
                        <div className="btn_box">
                            <button type="submit">Sign In</button>
                        </div>
                        <div className="redirect_box">
                            <p>Don't have any account? <Link to="/register">Sign Up</Link></p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="auth_right right_appear">
                <img src="image/Login-Bg.svg" alt="Login" />
            </div>
          </AuthBox>
        </>
    );
}

export default LoginPage;
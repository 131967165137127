import React from 'react';
import Router from './Router/Router';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Layouts
import AuthLayout from './Layouts/Auth-Layout';
import PageLayout from './Layouts/Page-Layout';

// pages 
import LoginPage from './Components/Login';
import RegisterPage from './Components/Register';


function App() {

  return (
    <> 
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<AuthLayout />}>
              <Route path='/' element={<Navigate to="login" />} />
              <Route index path='login' element={<LoginPage />} />
              <Route exact path='register' element={<RegisterPage />} />
            </Route>
            <Route path='notes' element={<PageLayout />}></Route>
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import { Link } from "react-router-dom";
import { AuthBox } from "../Styles/Auth-Style";
import { useState } from "react";
import axios from "axios";
import { Register_URL } from "../API/Api";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';




function RegisterPage() {

    const [show, setShow] = useState(false);
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
    })

    function handlePassowrd() {
        setShow(!show);
    }

    const handleInput = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    }


    function handleSubmit(e) {
        e.preventDefault();
        axios.post(Register_URL, user, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data);
            let status = res.data.status;

            if(status == "201"){
                toast.success('You Have Registered Successfully !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
                setUser({ name: '', email: '', password: '' });
            } else if(status == "401"){
                toast.error('This Username is already exist !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
                setUser({ name: '', email: '', password: '' });
            }
        })
        .catch(err => {
            console.log(err);
            toast.error('Registration Failed', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setUser({ name: '', email: '', password: '' });
        });
    }

    return (
        <>
            <ToastContainer />
            <AuthBox>
                <div className="auth_left">
                    <div className="left_inner left_appear">
                        <div className="logo">
                            <img src="image/Logo.png" alt="Logo" />
                            <h1>!! Create New Account !!</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="input_box">
                                <input type="text" name="name" value={user.name || ""} onChange={handleInput} required />
                                <label>Username</label>
                            </div>
                            <div className="input_box">
                                <input type="text" name="email" value={user.email || ""} onChange={handleInput} required />
                                <label>Email</label>
                            </div>
                            <div className="input_box">
                                <input type={show ? 'text' : 'password'} id="password" name="password" value={user.password || ""} onChange={handleInput} required />
                                <label>Password</label>
                                <a onClick={handlePassowrd}><i className={`fa-solid ${show ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                            </div>
                            <div className="btn_box">
                                <button type="submit">Sign Up</button>
                            </div>
                            <div className="redirect_box">
                                <p>Already have an account? <Link to="/login">Sign In</Link></p>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth_right fade_appear">
                    <img src="image/Register-Bg.svg" alt="Login" />
                </div>
            </AuthBox>
        </>
    );
}

export default RegisterPage;
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Nav";
import NoteIndex from "../Pages/Note-Index";
import { PageWrapper } from "../Styles/Page-Style";
import { useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function PageLayout() {

  const [login, setLogin] = useState(true);
  const [toastShown, setToastShown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("Login");
    if (!isLoggedIn || isLoggedIn !== "true") {
      navigate('/login');
    } else{
        toast.success('You Have Logged In Successfully !!', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
    }
  }, []);

  const handleLogout = () => {
    localStorage.setItem("Login", false);
    localStorage.removeItem("UserData");
    setLogin(false);
    setToastShown(false);
  }

    return (
        <>
          <ToastContainer />
          <PageWrapper>
            <Navbar onLogout={handleLogout} />
            <NoteIndex />
          </PageWrapper>
        </>
    );
}

export default PageLayout;